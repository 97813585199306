import "../FounderSection.css";
import founderPhoto from "../assets/img/founderPhoto.png";

const FounderSection = () => {
  return (
    <div class="responsive-container-block bigContainer">
      <div class="responsive-container-block Container bottomContainer">
        <div class="ultimateImg">
          <img class="mainImg" src={founderPhoto} />
          {/* <div class="purpleBox">
            <p class="purpleText">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eget
              purus lectus viverra in semper nec pretium mus.
            </p>
            <img
              class="stars"
              src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/mp5.svg"
            />
          </div> */}
        </div>
        <div class="allText bottomText">
          <p class="text-blk headingText">Our Founder</p>
          <p class="text-blk subHeadingText">Amit Verma</p>
          <p class="text-blk description">
            Consumer Business Strategist with 23 Years of Business Management
            experience in the Consumer Brands. Handled more than 50
            international and domestic brands 15 years of online business
            experience. Amit has Strong business expertise in General Trade,
            Modern Trade, Institutions, & Retail Industry Consulted more than 30
            brands in rhe Online Business Domain.
          </p>
          <a class="explore">View Domains</a>
        </div>
      </div>
    </div>
  );
};

export default FounderSection;
