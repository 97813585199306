import React, { Component } from "react";
import Header from "./Header";
import Home from "./HomePage";
import Services from "./Services";
import Footer from "./Footer";
import Ams from "./pages/Ams";
import { Switch, Route, Redirect } from "react-router-dom";
import Mam from "./pages/Mam";
import Bmc from "./pages/Bmc";
import Hcs from "./pages/Hcs";
import Mrc from "./pages/Mrc";
import Careers from "./Careers";
import About from "./About";
import FAQs from "./FAQs";
class Main extends Component {
  render() {
    return (
      <div className="wrapper">
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/services" component={Services} />
          <Route path="/about" component={About} />
          <Route
            path="/ecommerce-marketplace-account-management"
            component={Mam}
          />
          <Route
            path="/ecommerce-account-management-services"
            component={Ams}
          />
          <Route
            path="/ecommerce-business-management-company"
            component={Bmc}
          />
          <Route path="/hr-consultancy-services" component={Hcs} />
          <Route
            path="/mid-level-management-recruitment-consultants"
            component={Mrc}
          />
          <Route path="/Careers" component={Careers} />
          <Route path="/frequently-asked-questions" component={FAQs} />

          <Redirect to="/" />
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default Main;
