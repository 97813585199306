import LebenswerkRetail from "../assets/img/LebenswerkRetail.jpg";
import LebenswerkFmcg from "../assets/img/LebenswerkFmcg.jpg";
import accountmanagement from "../assets/img/account_management.JPG";
import ecommerce_service_provider from "../assets/img/ecom_service_provider.JPG";
import FounderSection from "./FounderSection";

const About = () => {
  return (
    <div class="row row-content fitplans about-us-section">
      <div class="col-12 text-center p-2" id="discount-block">
        <h2>About Us</h2>
        <h5>Lebenswerk Transforms Your Business</h5>
      </div>

      <div class="col-sm-6 deals my-auto" id="reservebtn">
        <h3 class="">What We Do </h3>

        <p class="mt-4">
          Business Transformation | e-Commerce Management | Sales Structure
          Planning | Corporate Planning | Annual Business Planning | Supply
          Chain Set-up | Processes Implementation | Training &amp; Development |
          Employees KRA &amp; KPI Formulation | GTM Planning | Financial
          Assessment | Performance Marketing
        </p>

        <a
          href="#reserve"
          data-toggle="modal"
          data-html="true"
          data-target="#reserveModal"
          data-placement="bottom"
          class="btn btn-lg "
          role="button"
        >
          Channel Sales
        </a>
        <a
          href="#reserve"
          data-toggle="modal"
          data-html="true"
          data-target="#reserveModal"
          data-placement="bottom"
          class="btn btn-lg "
          role="button"
        >
          LFR
        </a>
        <a
          href="#reserve"
          data-toggle="modal"
          data-html="true"
          data-target="#reserveModal"
          data-placement="bottom"
          class="btn btn-lg "
          role="button"
        >
          E-commerce
        </a>
      </div>

      <div class="col-sm-6 p-2">
        <div class="row">
          <div class="col p-5">
            {" "}
            <img src={accountmanagement} class="img-fluid" />
          </div>
        </div>
      </div>
      <br />

      <div class="col-sm-6 p-2">
        <div class="row d-none d-sm-block">
          <div class="col p-5">
            {" "}
            <img
              src={ecommerce_service_provider}
              style={{ border: "1px solid" }}
              class="img-fluid"
            />
          </div>
        </div>
      </div>
      <div class="col-sm-6 deals my-auto" id="reservebtn">
        <h3 class="">Who We Are </h3>

        <p class="mt-4">
          Lebenswerk's core team has vast experience into various consumer
          domains industries and worked across top-notch MNC &amp; Leading
          consumer companies in India. Academics background of our team are from
          XLRI - IMS - NIIT - FMS-ISS – Other Top Colleges, highly analytical
          and process oriented.
        </p>

        <a
          href="#reserve"
          data-toggle="modal"
          data-html="true"
          data-target="#reserveModal"
          data-placement="bottom"
          class="btn btn-lg "
          role="button"
        >
          XLRI
        </a>
        <a
          href="#reserve"
          data-toggle="modal"
          data-html="true"
          data-target="#reserveModal"
          data-placement="bottom"
          class="btn btn-lg "
          role="button"
        >
          IMS
        </a>
        <a
          href="#reserve"
          data-toggle="modal"
          data-html="true"
          data-target="#reserveModal"
          data-placement="bottom"
          class="btn btn-lg "
          role="button"
        >
          NIIT
        </a>
      </div>

      <div class="row  d-block d-sm-none">
        <div class="col p-5">
          {" "}
          <img src={ecommerce_service_provider} class="img-fluid" />
        </div>
      </div>

      <FounderSection />
    </div>
  );
};

export default About;
