import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <>
        <footer class="footer container-fluid btop">
          <div class="row footer-content">
            <div class="col-12 text-center mb-2">
              <h2>
                Lebenswerk Consulting<span class="dot ">.</span>
              </h2>
            </div>
            <div class="col-12 col-sm-4 pb-1">
              <div class="footer-widget">
                <h3>About us</h3>
                <p>
                  Lebenswerk provides Business Consulting, e-Commerce Business
                  Solutions, and Human Resource Services in FMCG, fashion,
                  footwear and all type of Consumer products with diversified
                  areas of expertise in Sales, Distribution, Business
                  Development, Marketing, Supply Chain, Demand Planning,
                  Business Analytics, Business Planning & Human Resource."
                </p>
              </div>
            </div>
            <div class="col-7 col-sm-4 pb-4">
              <div class="footer-widget ">
                <h3>Office Address</h3>
                <div class="footer-widget-content">
                  <p class="mb-0">
                    C-32, First and Second floor <br />
                    Kaushambi Above Allabhabad
                    <br />
                    Ghaziabad, UP 201012
                  </p>
                  <p class="mb-0">
                    <strong>Amit Verma</strong>
                  </p>
                  <a
                    href="mailto:amiet.verma@lebenswerk.co.in"
                    class="contact-link red"
                  >
                    amiet.verma@lebenswerk.co.in
                  </a>

                  <a href="tel:09953559179" class="contact-link">
                    9953559179
                  </a>
                </div>
              </div>
            </div>
            <div class="col-12 text-center col-md-4">
              <div class="footer-widget gymhour">
                <h3>Office Hours</h3>
                <div class="footer-widget-content">
                  <div class="open-times">
                    <ul class="opening-time p-2">
                      <li>
                        <span>
                          <i class="fa fa-check"></i>
                        </span>
                        <p>
                          <strong>Mon - Fri:</strong> 10am - 6pm
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="footer-nav my-auto">
                <div className="socialBlock">
                  <h3> Follow Us </h3>
                  {/* <!-- <h3 class="fs-subtitle">on social media to increase your Impact.</h3> --> */}
                  <div>
                    <div className="facebook">
                      <a href="https://www.facebook.com/Lebenswerk-Consulting-106740008008819/">
                        <i class="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div className="instagram">
                      <a href="https://www.instagram.com/consultinglebenswerk/">
                        <i class="fa fa-instagram" aria-hidden="true"></i>
                      </a>
                    </div>
                    <div className="twitter">
                      <a href="https://www.lebenswerk.co.in">
                        <i class="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-12 text-center copyright">
              <h6 class="contact-link red">
                © Copyright <span id="year"></span> Lebenswerk Consulting.
              </h6>
            </div>
          </div>
        </footer>
      </>
    );
  }
}
export default Footer;
