import "../ClientFeedback.css";
import sale from "../assets/img/sale.png";
import cart from "../assets/img/carts.png";
import moderntrade from "../assets/img/moderntrade.png";
import largeformatretail from "../assets/img/largeformatretail.png";
import brand from "../assets/img/brand.png";
import wholesale from "../assets/img/wholesale.png";
import giftbox from "../assets/img/giftbox.png";
import store from "../assets/img/store.png";
import red_chief_logo from "../assets/red_chief_logo.png";
import red_chief_product from "../assets/red_chief_product.jpg";
import swiss_beauty_logo from "../assets/swiss_beauty_logo.jpg";
import swiss_beauty_product from "../assets/swiss_beauty_product.jpg";
import gkhair_product from "../assets/gkhair_product.jpg";
import gkhair from "../assets/gkhair_logo.avif";
import phoebee_logo from "../assets/phoebee_logo.png";
import phoebee_product from "../assets/phoebee_product.jpg";
import metalsmith from "../assets/metalsmith.JPG";
import campus from "../assets/campus.jpg";
import draaxfashion from "../assets/draaxfashion.png";
import SHAHNAZ_LOGO from "../assets/SHAHNAZ_LOGO.png";
import shahnaz_products from "../assets/shahnaz_products.png";
import draax_products from "../assets/draax_products.jpg";
import metalsmith_products from "../assets/metalsmith_products.png";
import campus_products from "../assets/campus_products.jpg";

const KeyBrands = () => {
  return (
    <div class="responsive-container-block big-container keybrands">
      <div class="col-12 py-2">
        <div class="domain-grid">
          <div class="grid_row" id="main-container">
            <div class="grid_item" id="test-box">
              <div class="grid_img">
                <img src={red_chief_product} class="img-fluid" />
              </div>

              <div class="grid_text">
                <img src={red_chief_logo} class="img-fluid" />
              </div>
            </div>
            <div class="grid_item" id="test-box">
              <div class="grid_img">
                <img src={swiss_beauty_product} class="img-fluid" />
              </div>
              <div class="grid_text swiss_beauty">
                <img src={swiss_beauty_logo} class="img-fluid" />
              </div>
            </div>
            <div class="grid_item" id="test-box">
              <div class="grid_img">
                <img src={gkhair_product} class="img-fluid" />
              </div>
              <div class="grid_text">
                <img src={gkhair} class="img-fluid" />
              </div>
            </div>
            <div class="grid_item" id="test-box">
              <div class="grid_img">
                <img src={phoebee_product} class="img-fluid" />
              </div>
              <div class="grid_text phoebee">
                <img src={phoebee_logo} class="img-fluid" />
              </div>
            </div>
          </div>
          <div class="grid_row" id="main-container">
            <div class="grid_item">
              <div class="grid_img">
                <img src={metalsmith_products} class="img-fluid" />
              </div>
              <div class="grid_text metalsmith">
                <img src={metalsmith} class="img-fluid" />
              </div>
            </div>
            <div class="grid_item">
              <div class="grid_img">
                <img src={draax_products} class="img-fluid" />
              </div>
              <div class="grid_text swiss_beauty">
                <img src={draaxfashion} class="img-fluid" />
              </div>
            </div>
            <div class="grid_item">
              <div class="grid_img">
                <img src={campus_products} class="img-fluid" />
              </div>
              <div class="grid_text">
                <img src={campus} class="img-fluid" />
              </div>
            </div>
            <div class="grid_item">
              <div class="grid_img">
                <img src={shahnaz_products} class="img-fluid" />
              </div>
              <div class="grid_text shahnaz">
                <img src={SHAHNAZ_LOGO} class="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeyBrands;
