import "../ClientFeedback.css";
import star_rating from "../assets/img/star_rating.png";
import pravesh_kumar from "../assets/img/pravesh_kumar.png";
import manish_sharma from "../assets/img/manish_sharma.png";
import anmol_sharma from "../assets/img/anmol_sharma.png";

const Careers = () => {
  return (
    <div class="responsive-container-block big-container">
      <div class="responsive-container-block bg">
        <p class="text-blk title">Careers</p>
        <p class="text-blk desc">Work and Be Yourself with LebensWerk</p>
        <div class="responsive-container-block blocks careerblock">
          <div class="responsive-cell-block wk-desk-1 wk-tab-1 wk-mobile-1 wk-ipadp-1 content">
            <p class="text-blk info-block">
              <span className="textCenter">
                Drop your CV at amiet.verma@lebenswerk.co.in
              </span>
              <br></br>A member of our Talent Acquisition team will review your
              submission and if your experience aligns we will reach out to you
              to schedule a call.
            </p>
            <div class="responsive-container-block">
              {/* <div class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12 icon-block">
                <img class="profile-img" src={pravesh_kumar} />
              </div> */}
              <div class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12 text-block">
                <p class="text-blk name">Talent Acquisition Team</p>
                {/* <p class="text-blk desig">
                  {" "}
                  <img src={star_rating} />
                  <img src={star_rating} />
                  <img src={star_rating} />
                  <img src={star_rating} />
                  <img src={star_rating} />
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Careers;
