import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

class Header extends Component {
  render() {
    return (
      <>
        <div class="header-area">
          <div class="header-top_area">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-6 col-md-12 col-lg-8">
                  <div class="short_contact_list">
                    <ul>
                      <li>
                        <a href="tel:09953559179">
                          <FontAwesomeIcon icon={faPhone} /> +919953559179
                        </a>
                      </li>
                      <li>
                        <a href="mailto:amiet.verma@lebenswerk.co.in">
                          {" "}
                          <FontAwesomeIcon icon={faEnvelope} />{" "}
                          amiet.verma@lebenswerk.co.in
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-lg-4">
                  <div class="social_media_links d-none d-lg-block">
                    <a href="https://www.facebook.com/Lebenswerk-Consulting-106740008008819/">
                      <i class="fa fa-facebook"></i>
                    </a>

                    <a href="https://api.whatsapp.com/send?phone=919953559179">
                      <i class="fa fa-whatsapp whatspp-head"></i>
                    </a>
                    <a href="https://www.linkedin.com/in/lebenswerk-consulting">
                      <i class="fa fa-linkedin"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <nav class="navbar navbar-expand-sm navbar-custom">
          <div class="container main-nav">
            <img src={logo} alt="lebenswerk logo" />

            <Link to="/home" className="navbar-brand logo">
              Lebens<span class="lebensW">W</span>erk
              <span class="reg">&reg;</span>
            </Link>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#Navbar"
            >
              <span class="fa fa-bars"></span>
            </button>

            <div class="collapse navbar-collapse" id="Navbar">
              <ul class="navbar-nav">
                {/* <li class="nav-item">      <Link to="/home" className="nav-link"><FontAwesomeIcon icon={faAddressCard}/> About</Link></li> */}
                {/* <li class="nav-item"><a class="nav-link" href="/services.html"><FontAwesomeIcon icon={faCartArrowDown}/> Services</a></li>
                 */}
                <li class="nav-item">
                  <Link to="/about" className="nav-link">
                    About
                  </Link>
                </li>
                <li class="nav-item dropdown">
                  <Link className="nav-link">Services</Link>

                  <div class="dropdown-menu">
                    <li>
                      <Link
                        to="/ecommerce-marketplace-account-management"
                        className="dropdown-item"
                      >
                        E-commerce Marketplace
                      </Link>
                    </li>

                    <li class="divider"></li>
                    <li>
                      <Link
                        to="/ecommerce-account-management-services"
                        className="dropdown-item"
                      >
                        E-commerce Services
                      </Link>
                    </li>

                    <li class="divider"></li>

                    <li>
                      <Link
                        to="/hr-consultancy-services"
                        className="dropdown-item"
                      >
                        HR Services
                      </Link>
                    </li>

                    <li class="divider"></li>

                    <li>
                      <Link
                        to="/ecommerce-business-management-company"
                        className="dropdown-item"
                      >
                        Business Consulting
                      </Link>
                    </li>

                    <li class="divider"></li>

                    <li>
                      <Link
                        to="/mid-level-management-recruitment-consultants"
                        className="dropdown-item"
                      >
                        Manpower Requirement
                      </Link>
                    </li>

                    <li class="divider"></li>
                    <li>
                      <Link to="/services" className="dropdown-item">
                        More
                      </Link>
                    </li>
                  </div>
                </li>

                <li class="nav-item">
                  <a class="nav-link" href="https://lebenswerk.co.in/blog/">
                    <span class="fas fa-layer-group"></span> Blog
                  </a>
                </li>
                <li class="nav-item">
                  <Link to="/careers" className="nav-link">
                    Careers
                  </Link>
                </li>
                <li class="nav-item">
                  <Link to="/frequently-asked-questions" className="nav-link">
                    FAQs
                  </Link>
                </li>
              </ul>
              <ul class="navbar-nav mx-auto">
                <span class="GetInTouch">
                  <a
                    href="https://forms.gle/gAD9rvjVtNkrSsXu5"
                    class="btn btn-lg "
                    role="button"
                  >
                    Get in Touch
                  </a>
                </span>
              </ul>
            </div>
          </div>
        </nav>
      </>
    );
  }
}
export default Header;
