import "../ClientFeedback.css";
import star_rating from "../assets/img/star_rating.png";
import pravesh_kumar from "../assets/img/pravesh_kumar.png";
import manish_sharma from "../assets/img/manish_sharma.png";
import anmol_sharma from "../assets/img/anmol_sharma.png";

const ClientFeedback = () => {
  return (
    <div class="responsive-container-block big-container">
      <div class="responsive-container-block bg">
        <p class="text-blk title">Testimonials</p>
        <p class="text-blk desc">
          We have earned the trust of our most valuable customers.
        </p>
        <div class="responsive-container-block blocks">
          <div class="responsive-cell-block wk-desk-1 wk-tab-1 wk-mobile-1 wk-ipadp-1 content bottom">
            <p class="text-blk info-block">
              Good E-Commerce Consulting Agency. Total Transparent in Terms of
              Process and Charges. No Hidden Cost & No False Commitment. Enjoy
              the Services a lot.
            </p>
            <div class="responsive-container-block person">
              <div class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12 icon-block">
                <img class="profile-img" src={manish_sharma} />
              </div>
              <div class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12 text-block">
                <p class="text-blk name">Manish Sharma</p>
                <p class="text-blk desig">
                  {" "}
                  <img src={star_rating} />
                  <img src={star_rating} />
                  <img src={star_rating} />
                  <img src={star_rating} />
                  <img src={star_rating} />
                </p>
              </div>
            </div>
          </div>
          <div class="responsive-cell-block wk-desk-1 wk-tab-1 wk-mobile-1 wk-ipadp-1 content">
            <p class="text-blk info-block">
              Very good service with professional background. Staff is also very
              helpful. Extremely delighted with experience. Surely recommended.
            </p>
            <div class="responsive-container-block person">
              <div class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12 icon-block">
                <img class="profile-img" src={pravesh_kumar} />
              </div>
              <div class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12 text-block">
                <p class="text-blk name">Pravesh Kumar</p>
                <p class="text-blk desig">
                  {" "}
                  <img src={star_rating} />
                  <img src={star_rating} />
                  <img src={star_rating} />
                  <img src={star_rating} />
                  <img src={star_rating} />
                </p>
              </div>
            </div>
          </div>
          <div class="responsive-cell-block wk-desk-1 wk-tab-1 wk-mobile-1 wk-ipadp-1 content">
            <p class="text-blk info-block">
              Great experience with Lebenswerk. I am highly satisfied with the
              service that they provided me. They take a step ahead to help
              their client. Thank you.
            </p>
            <div class="responsive-container-block person">
              <div class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12 icon-block">
                <img class="profile-img" src={anmol_sharma} />
              </div>
              <div class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12 text-block">
                <p class="text-blk name">Anmol Sharma</p>
                <p class="text-blk desig">
                  <img src={star_rating} />
                  <img src={star_rating} />
                  <img src={star_rating} />
                  <img src={star_rating} />
                  <img src={star_rating} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientFeedback;
