import "../ClientFeedback.css";
import Accordion from "./Accordion";

const FAQs = () => {
  const accordionData = [
    {
      title: "Are E-Commerce Solutions Important?",
      content:
        "The e-commerce industry is ever-changing and e-commerce solutions are crucial for coping with frequent advancements. That’s why online sellers get in touch with an e-commerce business solutions provider who can keep up with constant technological and industrial e-commerce market trends. ",
    },
    {
      title: "How Will You Promote My Online Business?",
      content:
        "We have an experienced team of SEO Experts who have been handling digital promotions for e-commerce businesses for quite a few years. They boost your brand name through keyword integration and sponsored ads.",
    },
    {
      title: "What E-Commerce Services Do You Provide?",
      content:
        "Lebenswerk Consulting is a trusted e-commerce business management company that provides end-to-end solutions for \n •	E-Commerce Account Management \n •	Business Consultancy \n •	HR Consultancy \n •	Allied Services \n",
    },
    {
      title: "Which E-Commerce Platforms Do You Deal With?",
      content:
        "Some of the key e-commerce platforms we deal with are categorized above.",
    },
    {
      title: "I Want To Launch My Brand On Marketplaces, Will You Help Me?",
      content:
        "Of course. We have assisted many business owners in getting their brand listed online and expanding their verticals also.",
    },
  ];
  return (
    <div class="row row-content domain-expert faq-section">
      <div class="col-12 text-center p-2 py-3" id="discount-block">
        <h2>Frequently Asked Questions (FAQs)</h2>
        <h5>Discover the key features from Lebenswerk Consulting</h5>
      </div>

      <div class="col-12 py-2">
        {/* <div class="faq-grid">
                <div class="grid_row" id="main-container">
                  <div class="grid_item" id="test-box">
                    <span class="grid_text">
                      How Are E-Commerce Solutions Important?
                    </span>
                    <div class="grid_img">
                      <img src={sale} class="img-fluid" />
                    </div>
                  </div>
                </div>
                <div class="grid_row" id="main-container">
                  <div class="grid_item">
                    <span class="grid_text">Multi Brand Outlets</span>
                    <div class="grid_img">
                      <img src={brand} class="img-fluid" />
                    </div>
                  </div>
                </div>
                <div class="grid_row" id="main-container">
                  <div class="grid_item" id="test-box">
                    <span class="grid_text">Channel Sales</span>
                    <div class="grid_img">
                      <img src={sale} class="img-fluid" />
                    </div>
                  </div>
                </div>
                <div class="grid_row" id="main-container">
                  <div class="grid_item">
                    <span class="grid_text">Multi Brand Outlets</span>
                    <div class="grid_img">
                      <img src={brand} class="img-fluid" />
                    </div>
                  </div>
                </div>
                <div class="grid_row" id="main-container">
                  <div class="grid_item" id="test-box">
                    <span class="grid_text">Channel Sales</span>
                    <div class="grid_img">
                      <img src={sale} class="img-fluid" />
                    </div>
                  </div>
                </div>
              </div> */}
        <div className="faq-grid">
          {accordionData.map(({ title, content }) => (
            <Accordion title={title} content={content} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQs;
